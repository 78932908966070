(function(){
'use strict';

angular.module('classy.errors', ['classy']).config(['$locationProvider', function ($locationProvider) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });
  $locationProvider.hashPrefix('!');
}]);
})();