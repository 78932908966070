(function(){
"use strict";

errorPageController.$inject = ["$scope", "scAnalyticsHelper", "scMembersService", "scAuth"];
function errorPageController($scope, scAnalyticsHelper, scMembersService, scAuth) {
  $scope.SC = SC;

  scAuth.checkStatus();

  if ($scope.SC.member) {
    scMembersService.init(SC.member);
  }

  scAnalyticsHelper.setup($scope);

  switch ($scope.SC.statusCode) {
    case 500:
      $scope.errorMessage = {
        header: "We're currently experiencing technical difficulties.",
        subHeader: "Already tried refreshing the page? You can always reach out to the Classy Care Team at <a href='https://classy.org/help' target='_blank' rel='noreferrer noopener'>classy.org/help</a>."
      };
      break;

    case 403:
      $scope.errorMessage = {
        header: "You don't have access to this page.",
        subHeader: 'Please contact your admin to request access or learn more about permissions&nbsp;<a href="https://support.classy.org/s/article/roles-and-permissions" target="_blank" rel="noreferrer noopener">here</a>.'
      };
      break;

    default:
      $scope.errorMessage = {
        header: "We can't seem to find that page.",
        subHeader: 'Need some help? You can reach out to the Classy Care Team at <a href="https://classy.org/help" target="_blank" rel="noreferrer noopener">classy.org/help</a>.'
      };
      break;
  }
}

angular.module('classy.errors').controller('errorPageController', errorPageController);
})();